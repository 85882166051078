import React, { useState } from 'react';
import Navbar from '../components/Navbar.js'
import { Row, Col, Form, Button, Container } from 'react-bootstrap'
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import $ from 'jquery';
import { createGIF } from 'gifshot';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import html2canvas from 'html2canvas';
var turf = require('@turf/turf');

const regions = {
    "msfr": { "center": { lat: 39, lng: -95 }, "zoom": 4.6 },
    "conus": { "center": { lat: 39, lng: -95 }, "zoom": 4.6 },
    "alaska": { "center": { lat: 64, lng: -149 }, "zoom": 4.6 }
};
const GenerateGif = () => {
    const [times, setTimes] = useState([null, null])
    const [dates, setDates] = useState([null, null]);
    const [region, setRegion] = useState('msfr');
    const [frameDuration, setFrameDuration] = useState(null);
    const [opacity, setOpacity] = useState(null)
    const [map, setMap] = useState(null);
    const [timeRoot, setTimeRoot] = useState(null);
    const [width, setWidth] = useState(1200);
    const [height, setHeight] = useState(800);
    const [errorMsg, setErrorMsg] = useState(null);
    const [timeStep, setTimeStep] = useState(["10", "minutes"]);

    const handleOnLoad = map => {
        const timeDiv = document.createElement('div');
        timeDiv.style.fontSize = "25px";
        timeDiv.style.fontFamily = "Roboto";
        timeDiv.style.marginTop = "10px";
        timeDiv.style.marginLeft = "10px";
        const timeRoot = createRoot(timeDiv);
        setTimeRoot(timeRoot)
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(timeDiv);

        setMap(map)
    }

    const handleClick = () => {
        setErrorMsg(null)
        if (dates[0] === null || dates[1] === null) {
            setErrorMsg("Please enter dates")
            return
        }
        if (times[0] === null || times[1] === null) {
            setErrorMsg("Please enter times")
            return
        }
        if (frameDuration === null || frameDuration < 100 || frameDuration > 10000) {
            setErrorMsg("Please enter a valid frame duration")
            return
        }
        if (opacity === null || opacity < 0 || opacity > 100) {
            setErrorMsg("Please enter a valid opacity")
            return
        }
        if (map) {
            map.setOptions({ draggable: false, zoomControl: false });
        }
        else {
            setErrorMsg("Map not loaded")
            return
        }

        var dateArray;
        var satelliteFolders;
        var timesPHP = [times[0].substring(0, 2) + times[0].substring(3, 5) + "00", times[1].substring(0, 2) + times[1].substring(3, 5) + "00"];
        if (region === "msfr") {
            dateArray = getDates(dates[0], dates[1]);
            satelliteFolders = [];
        }

        if (region === "conus" || region === "alaska") {
            satelliteFolders = ["npp", "n20", "n19", "mob", "moc", "gpm", "f16", "f17", "f18"];
            dateArray = getDates(dates[0], dates[1]);
        }

        $.ajax({
            type: "GET",
            crossDomain: true,
            url: 'https://sfr.umd.edu/api/sfr/getImgs.php',
            data: { days: dateArray, satellites: satelliteFolders, times: timesPHP, region: region },
            dataType: 'json',
            error: function (jqxhr, textstatus, errorthrown) {
                console.log(textstatus);
                console.log(errorthrown);
            },
            success: function (obj, textstatus) {
                if (!('error' in obj)) {
                    if (obj.length > 0) {

                        var imgRegex;
                        if (region === "msfr") {
                            imgRegex = /[0-9]{8}_[0-9]{6}/;
                        } else {
                            imgRegex = /S[0-9]{8}_[0-9]{6}/;
                        }

                        /* Sort each day's urls by the time of the swath */
                        for (let day = 0; day < obj.length; day++) {
                            obj[day] = obj[day].sort(function (a, b) {
                                return a[0].match(imgRegex)[0].localeCompare(b[0].match(imgRegex))
                            });
                        }
                        obj = obj.flat();

                        var bounds = map.getBounds().toJSON()
                        var validImgs = []
                        for (let img = 0; img < obj.length; img++) {
                            var poly1 = turf.polygon([obj[img][2]]);
                            var poly2;
                            var poly3;
                            if (bounds.west < bounds.east) {
                                poly2 = turf.polygon([[[bounds.west, bounds.north], [bounds.east, bounds.north], [bounds.east, bounds.south], [bounds.west, bounds.south], [bounds.west, bounds.north]]]);
                                poly3 = turf.polygon([[[bounds.west - 360, bounds.north], [bounds.east - 360, bounds.north], [bounds.east - 360, bounds.south], [bounds.west - 360, bounds.south], [bounds.west - 360, bounds.north]]]);
                            }
                            else {
                                poly2 = turf.polygon([[[bounds.west - 360, bounds.north], [bounds.east, bounds.north], [bounds.east, bounds.south], [bounds.west - 360, bounds.south], [bounds.west - 360, bounds.north]]]);
                                poly3 = turf.polygon([[[bounds.west, bounds.north], [bounds.east + 360, bounds.north], [bounds.east + 360, bounds.south], [bounds.west, bounds.south], [bounds.west, bounds.north]]]);
                            }
                            if (turf.intersect(poly1, poly2) || turf.intersect(poly1, poly3)) {
                                validImgs.push(obj[img])
                            }
                        }

                        if (validImgs.length === 0) {
                            setErrorMsg("Images do not intersect with map bounds")
                            map.setOptions({ draggable: true, zoomControl: true });
                            return
                        }

                        var timeStepImgs = [];
                        var momentDate = moment(moment(dates[0]).format('YYYYMMDD') + timesPHP[0], "YYYYMMDDHHmmss")
                        for (let img = 0; img < validImgs.length; img++) {
                            const imgRegex = /[0-9]{8}_[0-9]{6}/;
                            var vals = validImgs[img][0].match(imgRegex)[0].split('_');
                            var dateStr = vals[0].substring(0, 4) + vals[0].substring(4, 6) + vals[0].substring(6, 8);
                            dateStr += vals[1].substring(0, 2) + vals[1].substring(2, 4) + vals[1].substring(4, 6);
                            var currMomentDate = moment(dateStr, "YYYYMMDDHHmmss")
                            if (currMomentDate >= momentDate) {
                                timeStepImgs.push(validImgs[img])
                                momentDate = moment(currMomentDate).add(Number(timeStep[0]), timeStep[1])
                            }
                        }

                        if (timeStepImgs.length > 20) {
                            setErrorMsg("Displaying the first 20 images")
                        }
                        var gifName = region.toUpperCase() +
                            "_S" + moment(moment(dates[0]).format('YYYYMMDD') + timesPHP[0], "YYYYMMDDHHmmss").format("YYYYMMDD-HHmmss") +
                            "_E" + moment(moment(dates[1]).format('YYYYMMDD') + timesPHP[1], "YYYYMMDDHHmmss").format("YYYYMMDD-HHmmss") +
                            "_Step-" + timeStep[0] + "-" + timeStep[1]
                        makeGif(timeStepImgs, gifName);
                    } else {
                        setErrorMsg("No images between start date/time and end date/time")
                        map.setOptions({ draggable: true, zoomControl: true });
                    }
                }
                else {
                    console.log(obj.error);
                    map.setOptions({ draggable: true, zoomControl: true });
                }
            }

        });
    }

    const makeGif = async (images, gifName) => {
        if (images) {
            var gifImages = []

            for (var i = 0; i < images.length && i < 20; i++) {
                await getImage('https://sfr.umd.edu/api/sfr/getImgs.php?path=' + images[i][0], images[i][1], i, gifImages)
            }

            const options = {
                images: gifImages,
                gifWidth: width,
                gifHeight: height,
                numWorkers: 4,
                frameDuration: frameDuration / 100.0,
                sampleInterval: 1,
            };

            createGIF(options, obj => {
                if (!obj.error) {
                    const link = document.createElement('a');
                    link.download = gifName + '.gif';
                    link.href = obj.image;
                    link.click();
                    link.remove();
                }
            });
            map.setOptions({ draggable: true, zoomControl: true });
        }
    }

    const getImage = (url, bounds, i, gifImages) => {
        return new Promise(function (resolve, reject) {
            var element = document.getElementById('Map')
            if (timeRoot) {
                timeRoot.render(
                    <div style={{ padding: 5, backgroundColor: "#fff" }}>
                        {imgInfo(url).join(' ')}
                    </div>
                );
            }

            var overlay = new window.google.maps.GroundOverlay(
                url,
                bounds,
                { map: map, opacity: opacity / 100.0 }
            );

            const displayCallback = () => {
                html2canvas(element, {
                    useCORS: true,
                }).then((canvas) => {
                    var link = document.createElement('a');
                    link.download = 'filename.png';
                    link.href = canvas.toDataURL()
                    //link.click();

                    gifImages[i] = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
                    canvas.remove();

                    timeRoot.render(
                        <div >
                        </div>
                    );
                    overlay.setMap(null);
                    resolve(1)
                });
            }

            waitForElementToDisplay('[src*="' + url + '"]', displayCallback, 100, 1000);
        });
    }

    return (
        <div>
            <Navbar />
            <Container fluid>
                <Row style={{ flexWrap: "nowrap" }}>
                    <Col md="auto" style={{ paddingTop: "20px", width: "390px" }}>
                        <Row xs="auto" className="m-1">
                            <h4>Max number of frames: 20</h4>
                        </Row>
                        <Form>
                            <Row xs="auto" className="m-2">
                                <Form.Group xs="auto">
                                    <Form.Label htmlFor="region">Region</Form.Label>
                                    <Form.Select
                                        onChange={(newRegion) => setRegion(newRegion.target.value)}
                                        id="region"
                                        className="mb-2"
                                    >
                                        <option value={"msfr"}>mSFR-CONUS</option>
                                        <option value={"conus"}>CONUS</option>
                                        <option value={"alaska"}>Alaska</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className="align-items-center m-2">
                                <Col xs="auto">
                                    <Form.Label htmlFor="startDate">
                                        Start Date
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(newDate) => {
                                            var nextDate = new Date(newDate.target.value)
                                            setDates([new Date(nextDate.getUTCFullYear(), nextDate.getUTCMonth(), nextDate.getUTCDate()), dates[1]])
                                        }}
                                        type='date'
                                        className="mb-2"
                                        id="startDate"
                                        placeholder="MM/DD/YYYY"
                                    />
                                </Col>
                                <Col xs="auto">
                                    <Form.Label htmlFor="startTime">
                                        Start Time
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(newTime) => setTimes([newTime.target.value, times[1]])}
                                        type='time'
                                        className="mb-2"
                                        id="startTime"
                                        placeholder="HH:MM"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center m-2">
                                <Col xs="auto">
                                    <Form.Label htmlFor="endDate">
                                        End Date
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(newDate) => {
                                            var nextDate = new Date(newDate.target.value)
                                            setDates([dates[0], new Date(nextDate.getUTCFullYear(), nextDate.getUTCMonth(), nextDate.getUTCDate())])
                                        }}
                                        type='date'
                                        className="mb-2"
                                        id="endDate"
                                        placeholder="MM/DD/YYYY"
                                    />
                                </Col>
                                <Col xs="auto">
                                    <Form.Label htmlFor="endDate">
                                        End Time
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(newTime) => setTimes([times[0], newTime.target.value])}
                                        type='time'
                                        className="mb-2"
                                        id="endDate"
                                        placeholder="HH:MM"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center m-2">
                                <Col xs="9">
                                    <Form.Label htmlFor="frameDuration">
                                        Frame Duration
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(newDuration) => setFrameDuration(newDuration.target.value)}
                                        type='number'
                                        className="mb-2"
                                        id="frameDuration"
                                        placeholder="100-10,000 milliseconds"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center m-2">
                                <Col xs="auto">
                                    <Form.Label htmlFor="opacity">
                                        Opacity
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(newOpacity) => setOpacity(newOpacity.target.value)}
                                        type='number'
                                        className="mb-2"
                                        id="opacity"
                                        placeholder="0-100"
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center m-2">
                                <Col xs="6">
                                    <Form.Label htmlFor="width">
                                        Width
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(newWidth) => {
                                            if (newWidth.target.value >= 100 && newWidth.target.value <= 2000) {
                                                setWidth(newWidth.target.value)
                                            }
                                        }}
                                        type='number'
                                        className="mb-2"
                                        id="width"
                                        placeholder="100-2000"
                                    />
                                </Col>
                                <Col xs="6">
                                    <Form.Label htmlFor="height">
                                        Height
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(newHeight) => {
                                            if (newHeight.target.value >= 100 && newHeight.target.value <= 2000) {
                                                setHeight(newHeight.target.value)
                                            }
                                        }}
                                        type='number'
                                        className="mb-2"
                                        id="height"
                                        placeholder="100-2000"
                                    />
                                </Col>
                            </Row>
                            <Row xs="auto" className="m-2">
                                <Form.Group xs="auto">
                                    <Form.Label htmlFor="timeStep">Time Step</Form.Label>
                                    <Form.Select
                                        onChange={(newTimeStep) => setTimeStep(newTimeStep.target.value.split(","))}
                                        id="timeStep"
                                        className="mb-2"
                                    >
                                        <option value={"10,minutes"}>10 minutes</option>
                                        <option value={"30,minutes"}>30 minutes</option>
                                        <option value={"1,hours"}>1 Hour</option>
                                        <option value={"2,hours"}>2 Hours</option>
                                        <option value={"4,hours"}>4 Hours</option>
                                        <option value={"12,hours"}>12 Hours</option>
                                        <option value={"1,days"}>1 Day</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Button onClick={handleClick} className="m-4">
                                Submit
                            </Button>
                            <Row className="align-items-center m-2">
                                <p style={{ color: "red" }}>
                                    {errorMsg}
                                </p>
                            </Row>
                        </Form>
                    </Col>
                    <Col md="auto">
                        <div id='GoogleMap' style={{ /*width: "0", height: "0", overflow: "hidden"*/ }}>
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_API_KEY}
                            >
                                <GoogleMap
                                    id='Map'
                                    mapContainerStyle={{
                                        width: width + "px",
                                        height: height + 'px'
                                    }}

                                    center={regions['conus']["center"]}
                                    zoom={regions['conus']["zoom"]}
                                    options={{
                                        isFractionalZoomEnabled: true,
                                        //disableDefaultUI: true
                                        streetViewControl: false,
                                        mapTypeControl: false,
                                        fullscreenControl: false
                                    }}
                                    onLoad={map => handleOnLoad(map)}
                                >
                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default GenerateGif;

/* Takes a starting date and stopping date and returns all the dates between them as an array inclusive */
function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDateMoment = moment(stopDate);
    while (currentDate <= stopDateMoment) {
        var formatDate = moment(currentDate).format('YYYYMMDD');
        dateArray.push([formatDate.substring(0, 4), formatDate.substring(4, 6), formatDate.substring(6, 8)]);
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}

const imgInfo = img => {
    const imgRegex = /[0-9]{8}_[0-9]{6}/;
    var vals = img.match(imgRegex)[0].split('_');
    vals[0] = vals[0].substring(4, 6) + '/' + vals[0].substring(6, 8) + '/' + vals[0].substring(0, 4);
    vals[1] = vals[1].substring(0, 2) + ':' + vals[1].substring(2, 4) + ':' + vals[1].substring(4, 6);
    var satellites = {
        "npp": "S-NPP", "n20": "NOAA-20", "n19": "NOAA-19", "mob": "Metop-B",
        "moc": "Metop-C", "gpm": "GPM", "f16": "F16", "f17": "F17", "f18": "F18"
    };
    for (var key in satellites) {
        if (img.includes(key)) {
            vals.unshift(satellites[key]);
            break;
        }
    }
    return vals;
};

function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
    var startTimeInMs = Date.now();
    (function loopSearch() {
        if (document.querySelector(selector) != null) {
            callback();
            return;
        }
        else {
            setTimeout(function () {
                if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
                    return;
                loopSearch();
            }, checkFrequencyInMs);
        }
    })();
}