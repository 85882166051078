import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleMap, LoadScript, GroundOverlay } from '@react-google-maps/api';
import MapControls from './MapControls';
import colorbar from '../logos/colorbar.png';
import $ from 'jquery';
import cmap from '../JSON/reverse_cmap.json';
import states from '../JSON/us-state-boundaries.json'

const libraries = ['drawing'];

//const id = [process.env.REACT_APP_MAP_ID];
const mid = 'eb5e1352a8e4c7f2';

// url and folder
//const url00 = "https://gpsmet.umd.edu/sfr";
//const fdn00 = "/data2/www/gpsmet.umd.edu";
const url00 = "https://sfr.umd.edu";
const fdn00 = "/home/jundong/www";

//const fn_pix = "api/sfr/getPixel.php";
const fn_pix = "api/sfr/getPixel.php";

const url_pix = url00 + '/' + fn_pix;

const regions = {
    "msfr": { "center": { lat: 39, lng: -95 }, "zoom": 4.5 },
    "conus": { "center": { lat: 39, lng: -95 }, "zoom": 4.5 },
    "alaska": { "center": { lat: 64, lng: -149 }, "zoom": 4.5 },
    "europe": { "center": { lat: 54, lng: 18 }, "zoom": 4.5 }
};

const Map = ({ images, region }) => {
    const [index, setIndex] = useState(0);
    const [controlsRoot, setControlsRoot] = useState(null);
    const [transparency, setTransparency] = useState(70);
    const [timeRoot, setTimeRoot] = useState(null);
    const [currBounds, setBounds] = useState(null);
    const [rectangleBool, setRectangleBool] = useState(false);
    const [countyBoundaries, setCountyBoundaries] = useState(false);
    const [drawingManager, setDrawingManager] = useState(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [map, setMap] = useState(null);
    const [label, setLabel] = useState(null);

    /* Set width to be 400 less than window size for NavRight
       Set height to 170 less than window height for Navbar. */
    useEffect(() => {
        function handleResize() {
            setWidth(Math.max(document.documentElement.clientWidth - 400, 550));
            setHeight(Math.max(document.documentElement.clientHeight - 170, 400));
        }

        setWidth(Math.max(document.documentElement.clientWidth - 400), 550);
        setHeight(Math.max(document.documentElement.clientHeight - 170), 400);

        window.addEventListener('resize', handleResize)
    }, [])

    /* Function to get parameters from MapControls */
    const getParams = (params) => {
        setIndex(params.index);
        setTransparency(params.transparency);
        setRectangleBool(params.rectangle);
        setCountyBoundaries(params.countyBoundaries);
    }

    /* Once Map exists, render Map controls */
    useEffect(() => {
        if (controlsRoot) {
            controlsRoot.render(
                <div>
                    <MapControls getParams={getParams} images={images} bounds={currBounds} rectangle={rectangleBool} region={region} />
                </div>
            );
        }
    }, [images, controlsRoot, rectangleBool, currBounds, region])

    /* Once Map exists, render text showing current image info */
    useEffect(() => {
        if (timeRoot && images && images[index] && images[index][0]) {
            timeRoot.render(
                <div style={{ padding: 5, backgroundColor: "#fff" }}>
                    {imgInfo(images[index][0]).join(' ')}
                </div>
            );
        }
        else if (timeRoot) {
            timeRoot.render(
                <div style={{ padding: 5, backgroundColor: "#fff" }}>
                    No Images Match Those Parameters
                </div>
            );
        }
    }, [images, index, timeRoot])

    /* Change drawing mode based on rectangle bool */
    useEffect(() => {
        if (drawingManager) {
            if (rectangleBool) {
                drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.RECTANGLE);
            }
            else {
                drawingManager.setDrawingMode(null);
            }
        }
    }, [rectangleBool, drawingManager])

    /* Buffer images */
    useEffect(() => {
        if (images && images[index]) {
            var preload = [];
            var preloadSize = 3;
            for (let i = 1; i <= preloadSize; i++) {
                preload[i] = new Image();
                preload[i].src = images[(index + i) % images.length][0];
            }
        }
    }, [index, images])

    useEffect(() => {
        if (map && map.getMapCapabilities().isDataDrivenStylingAvailable) {
            var featureLayer = map.getFeatureLayer("ADMINISTRATIVE_AREA_LEVEL_2")
            if (featureLayer.isAvailable) {
                featureLayer.style = ({ feature }) => {
                    return {
                        strokeColor: "#333333",
                        strokeOpacity: (map.getZoom() >= 5 && countyBoundaries) ? 0.5 : 0
                    }
                }
            }
        }
    }, [map, countyBoundaries])

    useEffect(() => {
        if (map) {
            var paths = []
            for (let i = 0; i < states.length; i++) {
                var coords = states[i]['st_asgeojson']['geometry']['coordinates']
                for (let j = 0; j < coords.length; j++) {
                    var path = coords[j]
                    if (path.length === 1) {
                        path = path[0]
                    }
                    var latLngPath = []
                    for (let k = 0; k < path.length; k++) {
                        latLngPath.push(new window.google.maps.LatLng(path[k][1], path[k][0]))
                    }
                    paths.push(latLngPath)
                }
            }

            // var polygons = new window.google.maps.Polygon({
            //     paths: paths,
            //     clickable: false,
            //     geodesic: false,
            //     strokeColor: '#5A5A5A',
            //     strokeOpacity: 1.0,
            //     strokeWeight: 0.4,
            //     fillOpacity: 0,
            //     map: map
            // });
        }
    }, [map])

    useEffect(() => {
        setLabel((prev) => {
            if (prev) {
                prev.setMap(null)
            }
            return prev
        })
    }, [index, images])

    const handleGroundOverlayClick = (latlng, img) => {
        var bounds = img[1]
        if (bounds.west > latlng.lng) {
            latlng.lng += 360
        }
        if (bounds.east < latlng.lng) {
            latlng.lng -= 360
        }
        var x = (latlng.lng - bounds.west) / (bounds.east - bounds.west)
        var y = (bounds.north - latlng.lat) / (bounds.north - bounds.south)

        var path = img[0];
        console.log(path);
        path = path.replace(url00, fdn00);
        //console.log(path);
        //console.log(url_pix);

        $.ajax({
            type: "GET",
            crossDomain: true,
            url: url_pix,
            data: { x: x, y: y, path: path },
            dataType: 'json',
            error: function (jqxhr, textstatus, errorthrown) {
                console.log(textstatus);
                console.log(errorthrown);
            },
            success: function (obj, textstatus) {
                if (obj['alpha'] === 0) {
                    delete obj['alpha']
                    var str = JSON.stringify(obj)
                    str = str.replaceAll("\"", "'")
                    var sfr = cmap[str];
                    var ssfr = '';
                    if (sfr === -0.01 || sfr === -0.02)  {
                        ssfr = '0.0 in/hr'
                    }
                    else if (sfr === 0)  {
                        ssfr = '<0.0004 in/hr'
                    }
                    else if (sfr === 10)  {
                        ssfr = '\u2265' + '0.4 in/hr'
                    }
                    else if (sfr > 10)  {
                        ssfr = '>0.4 in/hr'
                    }
                    else {
                        sfr = sfr / 25.0
                        if (sfr < 0.001)
                            ssfr = '<0.001 in/hr'
                        else
                            // ssfr = (Math.round(sfr / 25 * 10000) / 10000).toString() + ' in/hr'
                            ssfr = (Math.round(sfr * 1000) / 1000).toFixed(3) + ' in/hr'
                    }
                    // console.log(str)
                    // console.log(sfr)
                    // console.log(ssfr)

                    if (sfr !== undefined) {
                        var newLabel = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(latlng.lat, latlng.lng),
                            map: map,
                            icon: " ",
                            label: {
                                // fontWeight: 'bold',
                                text: ssfr,
                                fontSize: '14px',
                            },
                        });

                        if (label) {
                            label.setMap(null)
                        }
                        setLabel(newLabel)
                    }
                }
            }
        });
    }

    const handleOnLoad = map => {
        /* Create and position the component for controls on the map */
        const controlButtonDiv = document.createElement('div');
        controlButtonDiv.style.paddingRight = "10px";
        controlButtonDiv.style.fontSize = "15";
        controlButtonDiv.style.fontFamily = "Roboto";
        controlButtonDiv.style.justifyContent = "center";
        controlButtonDiv.style.alignContent = "center";
        controlButtonDiv.style.display = "flex";

        var featureLayer = map.getFeatureLayer("ADMINISTRATIVE_AREA_LEVEL_1");
        featureLayer.style = ({ feature }) => {
            return {
                strokeColor: "#000000",
                strokeOpacity: 1.0,
                strokeWeight: 1.0
        }}

        const controlRoot = createRoot(controlButtonDiv);
        controlRoot.render(
            <div>
                <MapControls getParams={getParams} images={images} region={region} />
            </div>
        );
        map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(controlButtonDiv);
        setControlsRoot(controlRoot)

        /* Create component that shows the current image info on the map */
        const timeDiv = document.createElement('div');
        timeDiv.style.fontSize = "25px";
        timeDiv.style.fontFamily = "Roboto";
        timeDiv.style.marginTop = "10px";
        timeDiv.style.marginLeft = "10px";
        const timeRoot = createRoot(timeDiv);
        setTimeRoot(timeRoot)
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(timeDiv);

        /* Display color bar on map */
        const colorbarDiv = document.createElement('div');
        colorbarDiv.style.paddingBottom = "25px";
        colorbarDiv.style.marginLeft = "-70px";
        const colorbarRoot = createRoot(colorbarDiv);
        colorbarRoot.render(
            <div>
                <img src={colorbar} alt="Colorbar" className="Colorbar" width="350" />
            </div>
        );
        map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(colorbarDiv);

        /* Create drawing manager for box selection */
        const drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.RECTANGLE,
            drawingControl: false
        });

        drawingManager.setMap(map);
        setDrawingManager(drawingManager);

        window.google.maps.event.addListener(drawingManager, 'rectanglecomplete', function (rectangle) {
            map.fitBounds(rectangle.bounds, 0);
            rectangle.setMap(null);
        });

        var timeout;
        window.google.maps.event.addListener(map, 'bounds_changed', function () {
            window.clearTimeout(timeout);
            timeout = window.setTimeout(function () {
                // setBounds(map.getBounds().toJSON())
                setBounds(JSON.stringify(map.getBounds()))
            }, 100);
        });

        map.setOptions({ streetViewControl: false });
        setMap(map);
    };

    /* Function that takes the image url and returns a string of the satellite, date, and time of the image */
    const imgInfo = img => {
        const imgRegex = /[0-9]{8}_[0-9]{6}/;
        var vals = img.match(imgRegex)[0].split('_');
        vals[0] = vals[0].substring(4, 6) + '/' + vals[0].substring(6, 8) + '/' + vals[0].substring(0, 4);
        vals[1] = vals[1].substring(0, 2) + ':' + vals[1].substring(2, 4) + ':' + vals[1].substring(4, 6) + ' UTC';
        var satellites = {
            "npp": "S-NPP", "n20": "NOAA-20", "n19": "NOAA-19", "mob": "Metop-B",
            "moc": "Metop-C", "n21": "NOAA-21", "gpm": "GPM", "f16": "F16", "f17": "F17", "f18": "F18"
        };
        for (var key in satellites) {
            if (img.includes(key)) {
                vals.unshift(satellites[key]);
                break;
            }
        }
        return vals;
    };

    const generateGif = () => {
        var imgs = []

        for (let i = 0; i < images.length; i++) {
            imgs.push(images[i][0].replace("https://cics.umd.edu/~jdong/", "/home/jdong/www/"))
        }

        $.ajax({
            type: "GET",
            crossDomain: true,
            url: 'https://cics.umd.edu/~jdong/api/sfr/makeGif.php',
            data: {},
            xhrFields: {
                responseType: 'blob'
            },
            error: function (jqxhr, textstatus, errorthrown) {
                console.log(textstatus);
                console.log(errorthrown);
            },
            success: function (obj, textstatus) {
                console.log(obj)
                const link = document.createElement("a");
                link.href = URL.createObjectURL(obj);
                link.download = "sample.gif";
                link.click();
                URL.revokeObjectURL(link.href);
            }
        });
    }

    return (
        <div>
            <LoadScript
                googleMapsApiKey={process.env.REACT_APP_API_KEY}
                libraries={libraries}
                // version={"beta"}
                mapIds={[mid]}
            >
                <GoogleMap
                    mapContainerStyle={{
                        width: width + "px",
                        height: height + 'px'
                    }}

                    center={regions[region]["center"]}
                    zoom={regions[region]["zoom"]}
                    options={{
                        mapId: mid,
                        isFractionalZoomEnabled: true,
                        streetViewControl: false,
                        mapTypeControl: false
                    }}
                    onLoad={map => handleOnLoad(map)}
                    onDblClick={() => generateGif()}
                >
                    {images && images[index] && images[index][0] && images[index][1] ? <GroundOverlay
                        key={images[index][0]}
                        url={images[index][0]}
                        bounds={images[index][1]}
                        opacity={transparency / 100.0}
                        onClick={(e) => handleGroundOverlayClick(e.latLng.toJSON(), images[index])}

                    /> : null}
                </GoogleMap>
            </LoadScript>
        </div>
    )
}

export default Map



